<template>
  <div class="permission">
    <div class="permission-mask">
    </div>
    <div class="permission-title">
      <img
        src="imgs/permission.png"
        alt=""
      >
      <img
        class="close-btn"
        src="imgs/删除.png"
        @click="changePermission"
        alt=""
      >
      <p style="margin-top: 0.2rem">གསང་བའི་དབང་ཆ་དགོས་ན་ཁྱེད་ཀྱིས་</p>
      <p>APPཡི་ཁྲོད་དུ་ཁ་ཕྱེ།</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changePermission() {
      this.$store.commit('changePermission')
    }
  }
}

</script>

<style lang="less" scoped>
.permission {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99999;
}
.permission-mask {
  background: #000000;
  opacity: 0.15;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}
.permission-title {
  width: 5rem;
  height: 4.76rem;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: absolute;
  right: 1.25rem;
  top: 3.1rem;
  color: #666666;
  line-height: 0.53rem;
  font-size: 0.32rem;
  text-align: center;
  img {
    width: 4.7rem;
    height: 2.9rem;
  }
  .close-btn {
    position: absolute;
    top: 0.05rem;
    right: 0.05rem;
    width: 0.4rem;
    height: 0.4rem;
    padding: 0.1rem;
  }
}
</style>