import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMask: false,
    showPermission: false
  },
  mutations: {
    changeMask(state) {
      state.showMask = !state.showMask
    },
    changePermission(state) {
      state.showPermission = !state.showPermission
    },
  }
})