import { render, staticRenderFns } from "./ShareMask.vue?vue&type=template&id=dbbc2cce&scoped=true&"
import script from "./ShareMask.vue?vue&type=script&lang=js&"
export * from "./ShareMask.vue?vue&type=script&lang=js&"
import style0 from "./ShareMask.vue?vue&type=style&index=0&id=dbbc2cce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbbc2cce",
  null
  
)

export default component.exports