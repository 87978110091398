import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueVideoPlayer from 'vue-video-player'
import {
  Slider,
  Icon,
} from 'element-ui';
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-flash'
import 'videojs-contrib-hls'
import 'normalize.css'
import VueXgplayer from 'vue-xgplayer'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css';
import Toast from 'vant/lib/toast';
import 'vant/lib/toast/style';

// if (process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = 'http://192.168.3.15:9093/';
// }
// axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.config.productionTip = false
Vue.use(VueVideoPlayer)
Vue.use(Slider)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(VueXgplayer)
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')