<template>
  <div class="share">
    <div
      class="share-mask"
      @click="showShare"
    >
    </div>
    <img
      src="imgs/分享引导页.png"
      alt=""
    >
    <div class="share-title">
      <img
        src="imgs/right-btn-share.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showShare() {
      this.$store.commit('changeMask')
    }
  }
}

</script>

<style lang="less" scoped>
.share {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
}
.share-mask {
  background: #000000;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}
img {
  position: absolute;
  right: 0.5rem;
  top: 0.8rem;
  width: 1.2rem;
}

.share-title {
  position: absolute;
  right: 1.5rem;
  top: 1.3rem;
  color: #ffffff;
  line-height: 0.45rem;
  font-size: 0.32rem;
  img {
    width: 2rem;
  }
}
</style>