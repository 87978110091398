<template>
  <div class="home">
    <img src="imgs/home-title.png" alt="">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  components: {
  }
}
</script>
<style lang="less" scoped>
.home{
  text-align: center;
}
 img{
   width: 5rem;
   margin: 4rem auto;
 }
</style>
