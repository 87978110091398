<template>
  <div id="app">
    <router-view />
    <transition name="fade">
      <ShareMask v-if="showMask"></ShareMask>
      <Permission v-if="showPermission"></Permission>
    </transition>
  </div>
</template>
<script src="//cdn.jsdelivr.net/npm/xgplayer@1.1.4/browser/index.js" charset="utf-8"></script>
<script src="//cdn.jsdelivr.net/npm/xgplayer-hls.js/browser/index.js" charset="utf-8"></script>
<script>
import ShareMask from '@/components/ShareMask.vue';
import Permission from '@/components/Permission.vue';
import { mapState } from 'vuex';
export default {
  components: {
    ShareMask,
    Permission
  },
  computed: mapState({
    showMask: state => state.showMask,
    showPermission: state => state.showPermission
  })
}
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  font-size: 0.32rem;
}
html,
body,
p,
div {
  margin: 0;
  padding: 0;
}
.ellipsis-1 {
  overflow: hidden; // 隐藏
  text-overflow: ellipsis; // 省略号
  white-space: nowrap; // 不换行
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; // 2行
  word-break: break-all; // 换行
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
