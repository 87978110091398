import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/audio-album',
    name: 'audio-album',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AudioAlbum.vue')
  },
  {
    path: '/video-album',
    name: 'video-album',
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoAlbum.vue')
  },
  {
    path: '/audio',
    name: 'audio',
    component: () => import(/* webpackChunkName: "about" */ '../views/Audio.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "about" */ '../views/Live.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
